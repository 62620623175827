//import $ from "jquery";
import Filter from './modules/filter';
import Animations from './modules/animations';
import NewsletterForm from './modules/newsletter-form';
import Navigation from './modules/navigation';
import Form from './modules/form';
import VideoLightbox from './modules/videoLightbox';
import ProductInfoPods from './modules/productInfoPods';
import Accordion from './modules/accordion';
import ImageGallery from './modules/imageGallery';
import ExternalLinks from './modules/externalLinks';
// require('fslightbox');

Animations();
NewsletterForm();
Navigation();
Filter();
Form();
ExternalLinks();
VideoLightbox();
ProductInfoPods();
Accordion();
ImageGallery();

var $heroSkip = $('.hero-explore .wrap')

$heroSkip.each(function () {
    $(this).on('click', function () {
        $('html, body').animate({
            scrollTop: $(this).parent().offset().top + $(this).parent().outerHeight()
        });
    });
});