import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function Animations() {
    animations()    
}

//
// Define your functions here
//
function animations() {

    gsap.registerPlugin(ScrollTrigger);

    //apply class is-active to all sections when they are in viewport
    const sections = document.querySelectorAll('section');

    sections.forEach((section) => {
        gsap.to(section, {       
            scrollTrigger: {
              trigger: section,
              start: 'top center',
              end: () => `+=${section.clientHeight + 30}`,
              toggleActions: 'play reverse none reverse',
              toggleClass: {targets: section, className: "is-active"}
          }
        });

    });

    // Grab the prefers reduced media query.
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

    if (mediaQuery && !mediaQuery.matches) {
        
        runAnimations();
    }

}

function runAnimations() {

    // fade In section
    const sectionsFadeUp = document.querySelectorAll('.fadeUp');

    sectionsFadeUp.forEach((section) => {

        section.style.opacity = 0;

        gsap.to(section, {
            opacity: 1,
            scrollTrigger: {
                trigger: section,
                start: 'top bottom-=200',
                toggleActions: 'play none none reverse'
            }
        });

    });

}