export default function externalLinks() {
	init()
}

function init() {
	var links = document.querySelectorAll('a.button, a.text-link');
	for (var i = 0; i < links.length; i++) {
		if (isExternalReplace(links[i].href) || links[i].target == "_blank") {
			links[i].classList.add("external-link");

			var svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
				useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');

			useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', '#sprite-icon-external');
			svgElem.appendChild(useElem);
			links[i].appendChild(svgElem);
		}
	}
}

function isExternalReplace(url) {
	return !(location.href.replace("http://", "").replace("https://", "").split("/")[0] === url.replace("http://", "").replace("https://", "").split("/")[0]);   
}