export default function Form () {
  formFunctions()
}


function formFunctions() {    
    document.querySelectorAll('.reset-form').forEach(item => {
    
      item.addEventListener('click', function() {
          var inputs = this.closest('form').elements;
          
          for (let index = 0; index < inputs.length; index++) {
            const el = inputs[index];
            
            switch(el.type) {
              case "checkbox":
                el.removeAttribute("checked");
                break;
          
              default:
                el.value = "";
                break;
            }
          }
      });
      
    });

    // responsive recaptcha
  let recaptcha = document.querySelector('.g-recaptcha');
  
    function recaptchaResponsive() {
      let windowWidth = window.innerWidth;

      if (windowWidth < 500) {
        let transformScale = windowWidth/460;

        if (transformScale <= 1) {
          recaptcha.style.transform = 'scale(' + transformScale + ')';
        }
      }
  }
  
  if (recaptcha) {
      
    window.onresize = recaptchaResponsive;

    window.onload = recaptchaResponsive;
  }
}
