export default function Navigation () {
  clickHandlers()
}

function clickHandlers() {
  var $menuIcon = $('.menu-icon'),
      $siteHeader = $('.site-header'),
      $body = $('body'),
      $mobileMenuItem = $('.primary-nav li.has-children > a')

  $menuIcon.on('click', function() {
    $(this).toggleClass('-nav-open')
    $siteHeader.toggleClass('-nav-open')
    $body.toggleClass('-nav-open')
  })

  $mobileMenuItem.on('click', function(e) {
    e.preventDefault()
    $(this).toggleClass('-show-sub-menu')
    $(this).next('.sub-menu').slideToggle(250)
  })

  var $languageFilter = $('.language-filter');
  $languageFilter.on("click", function () {

      $(this).toggleClass('language-active');

      document.querySelector('.language-dropdown').classList.toggle('language-filter-active');
  });

  /* For hero areas with Light theme */
  if ($('.hero-area').hasClass('hero-area--light')) {
    $siteHeader.addClass('site-header--light') 
  }
}