export default function ProductInfoPods () {
    // apply flip class to alternating Product Info Pods
    $('.product-info-pod:not(.product-info-pod--white-bg)').each(function(index) {
        if (index % 2 === 0) {
            $(this).next('.product-info-pod:not(.product-info-pod--white-bg)').addClass('-flip')
        }
    })

    /* Two Column Blocks. Don't apply flipping to blocks with 
     * two content columns.
     */
    $('.two-column-block').each(function(index) {
        var contentColCount = $(this).find('.two-column-block__content-col').length

        if (contentColCount < 2) {
            if (index % 2 === 1) {
                $(this).addClass('-flip')
            }
        }

        if (index % 2 === 1) {
            $(this).addClass('-bg-black')
        }
    })

    $('.product-info-pod__gallery').each(function () {
        var $gallerySlider = $(this).find('.product-info-pod__slider')

        $gallerySlider.slick({
            dots: true,
            appendDots: $gallerySlider.parents('.product-info-pod__gallery').find('.product-info-pod__galleryDots'),
            arrows: true,
            appendArrows: $gallerySlider.parents('.product-info-pod__gallery').find('.product-info-pod__galleryArrows'),
            slidesToShow: 1,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 569,
                    settings: {
                        arrows: false
                    }
                }
            ]
        })
    })
}