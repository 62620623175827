import 'slick-carousel'

export default function ImageGallery () {
    init()
}

function init () {
    var $imageGallery = $('.image-gallery')

    $imageGallery.each(function() {
        var $imageGallerySlider = $(this).find('.image-gallery-slider')

        $imageGallerySlider.slick({
            dots: true,
            appendDots: $imageGallerySlider.parents('.image-gallery').find('.image-gallery-dots-wrapper'),
            arrows: true,
            appendArrows: $imageGallerySlider.parents('.image-gallery').find('.image-gallery-arrows-wrapper'),
            slidesToShow: 1,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 569,
                    settings: {
                        arrows: false
                    }
                }
            ]
        })
    });
}