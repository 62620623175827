export default function accordion () {
  $(document).on('click', '.accordion-heading' , accToggle);
}

function accToggle () {
  var accordion = $(this).parent('.accordion');
  var siblingAccordion = accordion.siblings('.accordion');

  if (accordion.hasClass('active')) {
    accordion.removeClass('active');
    accordion.find('> .accordion-content').slideUp(250);
    return false
  }

  siblingAccordion.removeClass('active');
  siblingAccordion.find('> .accordion-content').slideUp(250);

  accordion.toggleClass('active');
  accordion.find('> .accordion-content').slideToggle(250, function() {
    // moveUser(accordion)
  });
}

// function moveUser (elem) {
//   /* Move the user to the top of the activated accordion */

//   if ($(window).width() > 568) {
//     $('html,body').animate({
//       scrollTop: elem.offset().top - 180 // this is to account for the persistent nav
//     },500)
//   } else {
//     $('html,body').animate({
//       scrollTop: elem.offset().top - 90 // this is to account for the persistent nav
//     },500)
//   }
// }