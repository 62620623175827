export default function NewsletterForm () {
  formLabel()
}

function formLabel() {
  var formWrap = document.querySelectorAll('.umbraco-forms-field-wrapper');
  for (var i = 0; i < formWrap.length; i++) {
    var formInput = formWrap[i].childNodes[1];
    formInput.addEventListener('keyup', function () {
      var formLabel = this.nextElementSibling;
      if(this.value.length != 0) {
        formLabel.style.top = '-18px';
      } else {
        formLabel.style.top = '0';
      }
    });
  }
}
