export default function VideoLightbox () {
    clickHandlers()
}
  
function clickHandlers() {
    var $lightboxButton = $('.js-lightbox'),
        $lightboxClose = $('.js-lightbox-close'),
        $lightbox = $('.lightbox'),
        $lightboxIframe = $('.lightbox iframe'),
        $videoId

    $lightboxButton.on('click', function(e) {
        e.preventDefault()

        // Get YouTube video ID
        $videoId = $(this).data('video-id')

        // Populate iframe in the lightbox
        $lightboxIframe.attr('src','https://www.youtube.com/embed/' + $videoId)

        $lightbox.addClass('-active')
    })

    $lightboxClose.on('click', function(e) {
        e.preventDefault()
        
        // Destroy iframe in the lightbox
        $lightboxIframe.attr('src','')

        $lightbox.removeClass('-active')
    })
}